<template>
  <div>
    <BackNavigatorBar />
    <div class="page-view">

      <div class="page-view-item">
        <div class="page-view-item-name">报告名称</div>
        <div class="page-view-item-content">
          <input type="text" placeholder="限20个字" class="cs-input" maxlength="20" v-model="addReportParams.name">
        </div>
      </div>

      <div class="page-view-item">
        <div class="page-view-item-name">检查日期</div>
        <div class="page-view-item-content">
          <CSSelect height="40px" i-width="36px">
            <el-date-picker
                style="width: 270px"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm"
                format="yyyy-MM-dd HH:mm"
                v-model="addReportParams.startDate"
                :editable="false"
                placeholder="选择日期">
            </el-date-picker>
          </CSSelect>
          <span>-</span>
          <CSSelect height="40px" i-width="36px">
            <el-date-picker
                type="datetime"
                style="width: 270px"
                v-model="addReportParams.endDate"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                :editable="false"
                placeholder="选择日期">
            </el-date-picker>
          </CSSelect>
          <button class="btn btn-primary" @click="queryAssessmentItemsLog">查询</button>
        </div>
      </div>

      <div class="page-view-item need-border">
        <div class="page-view-item-name top">考核汇总</div>
        <div class="page-view-item-content">
          <CSTabBar :tabbar="assessmentTabBar" :checked-tab="checkedTab" @changeTabBar="changeTabBar"/>
          <div style="max-height: 300px; overflow-y: auto;padding:0 2px;">
            <table
                border="1"
                v-if="checkedTab === 1"
                class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
            >
              <thead>
              <tr class="head_row">
                <th style="min-width: 230px">扣分时间</th>
                <th>部门</th>
                <th>扣分项</th>
                <th>扣分</th>
              </tr>
              </thead>
              <tbody>
              <template v-if="assessmentItemLogData.logs && assessmentItemLogData.logs.length > 0">
                <tr v-for="log in assessmentItemLogData.logs"
                    :key="log.id">
                  <td>{{log.createTime || '-'}}</td>
                  <td>{{departmentTypes[log.dutyType] || '-'}}</td>
                  <td>{{log.itemName || '-'}}</td>
                  <td>{{log.score}}</td>
                </tr>
              </template>
              </tbody>
            </table>

            <table
                border="1"
                v-if="checkedTab === 2"
                class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
            >
              <thead>
              <tr class="head_row">
                <th style="min-width: 230px">提交时间</th>
                <th>部门</th>
                <th>得分</th>
              </tr>
              </thead>
              <tbody>
              <template v-if="assessmentItemLogData.logs && assessmentItemLogData.logs.length > 0">
                <tr v-for="log in assessmentItemLogData.logs"
                    :key="log.id">
                  <td>{{log.createTime || '-'}}</td>
                  <td>{{departmentTypes[log.dutyType] || '-'}}</td>
                  <td>
                    <span class="allow-click" @click="lookAssessmentItem(log)">{{log.score}}/{{log.scoreCount}}</span>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>

            <table
                border="1"
                v-if="checkedTab === 3"
                class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
            >
              <thead>
              <tr class="head_row">
                <th style="min-width: 230px">扣分时间</th>
                <th>部门</th>
                <th>扣分项</th>
                <th>扣分</th>
                <th style="min-width: 180px;">相关巡检/工单</th>
              </tr>
              </thead>
              <tbody>
              <template v-if="assessmentItemLogData.logs && assessmentItemLogData.logs.length > 0">
                <tr v-for="log in assessmentItemLogData.logs"
                    :key="log.id">
                  <td>{{log.createTime || '-'}}</td>
                  <td>{{departmentTypes[log.dutyType] || '-'}}</td>
                  <td>{{log.itemName || '-'}}</td>
                  <td>{{log.score}}</td>
                  <td>
                    <span class="allow-click" @click="lookSystemAssessmentTask(log)">查看</span>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="page-view-item">
        <div class="page-view-item-name top">受检部门</div>
        <div class="page-view-item-content">
          <p v-for="(department, key) in inspectionDepartmentList"
            :key="`${department.id}-${key}`" >
            <span style="margin-right: 10px;">{{department.name}}</span>
            <svg class="icon" aria-hidden="true" @click="deleteInspectionDepartment(key)">
              <use xlink:href="#icon-menushanchu"></use>
            </svg>
          </p>
          <button class="btn btn-primary" @click="openAddInspectionDepartmentDialog">
            <svg class="icon" aria-hidden="true" style="font-size: 18px;vertical-align: middle;margin-right: 10px;">
              <use xlink:href="#icon-menujiahao"></use>
            </svg>
            <span style="display: inline-block;vertical-align: middle;">添加部门</span>
          </button>
        </div>
      </div>

      <div class="page-view-item">
        <div class="page-view-item-name">检查结果</div>
        <div class="page-view-item-content">
          <CSRadio :items="{1: '合格', 0: '不合格'}" v-model="addReportParams.isQualified"></CSRadio>
        </div>
      </div>

      <div class="page-view-item">
        <div class="page-view-item-name">检查人</div>
        <div class="page-view-item-content">
          {{staffInfo.name}}
        </div>
      </div>

      <button class="btn btn-primary btn-create-report" @click="createReport">生成品质检查报告</button>
    </div>

    <!--  添加受检部门  -->
    <CSDialog :dialog-width="418" dialog-title="添加部门" :dialog-visible="addInspectionDepartmentDialog.visible"
      @onClose="addInspectionDepartmentDialog.onClose" @onConfirm="addInspectionDepartmentDialog.onConfirm"
    >
      <template v-slot:dialog-content>
        <div class="dialog-form">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="width: 48px;">部门</div>
            <div>
              <CSSelect height="40px" i-width="36px">
                <select v-model="addInspectionDepartmentDialog.currentCheckedDepartment" style="color: #999999">
                  <option value="" selected>请选择</option>
                  <option v-for="department in addInspectionDepartmentDialog.canAddDepartmentList"
                      :key="department.id" :value="department">
                    {{department.name}}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>

  </div>
</template>

<script>
import BackNavigatorBar from "@/components/common/BackNavigatorBar";
import CSSelect from "@/components/common/CSSelect";
import CSTabBar from "@/components/common/CSTabBar";
import CSRadio from "@/components/common/CSRadio";
import CSDialog from "@/components/common/CSDialog";
import {DEPARTMENT_TYPES} from "@/constant";
import {
  createQualityReportUrl,
  queryEvaluatorUserDutyTypeUrl,
  adminQueryItemLogUrl,
  queryInspectionUrl, queryTicketUrl
} from "@/requestUrl";
import dayjs from "dayjs";

export default {
  name: "QualityReportAdd",
  components: {
    BackNavigatorBar,
    CSSelect,
    CSTabBar,
    CSRadio,
    CSDialog,
  },
  data() {
    return {
      assessmentTabBar: {
        1: '业务考核',
        2: '通用考核',
        3: '系统自动考核'
      },
      checkedTab: 1,
      addReportParams: {
        name: '',
        startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD') + ' 00:00',
        endDate: dayjs().format('YYYY-MM-DD') + ' 23:59',
        isQualified: 1,
      },
      assessmentItemLogData: {},
      staffInfo: this.$vc.getCurrentStaffInfo(),
      departmentTypes: {
        [DEPARTMENT_TYPES.DIRECTOR]: "总监办公室",
        [DEPARTMENT_TYPES.MANAGER]: "经理办公室",
        [DEPARTMENT_TYPES.ENGINEERING]: "工程部",
        [DEPARTMENT_TYPES.CUSTOMER_SERVICE]: "客服部",
        [DEPARTMENT_TYPES.PROCTER_GAMBLE]: "保洁部",
        [DEPARTMENT_TYPES.SECURITY]: "保安部",
        [DEPARTMENT_TYPES.PERSONNEL]: "人事部",
        [DEPARTMENT_TYPES.FINANCIAL]: "财务部",
        [DEPARTMENT_TYPES.OPERATIONS]: "运营部",
        [DEPARTMENT_TYPES.INVESTMENT_PROMOTION]: "招商部",
        [DEPARTMENT_TYPES.QUALITY]: "品质管理部",
      },
      inspectionDepartmentList: [],
      addInspectionDepartmentDialog: {
        visible: false,
        canAddDepartmentList: [],
        currentCheckedDepartment: '',
        onClose: () => this.addInspectionDepartmentDialog.visible = false,
        onConfirm() {},
      },

    }
  },
  created() {
    this.queryAssessmentItemsLog();
  },
  methods: {
    /**
     * 查看考核项
     * @param {Array} log 考核项记录
     * */
    lookAssessmentItem(log) {
      this.$CSDialog.alert({
        title: '通用考核项',
        messageHtml: `
            <table class="modal-inner-table" style="width: 100%;min-width: 100%;">
                <thead>
                  <tr>
                    <th>考核项</th>
                    <th>分值</th>
                  </tr>
                </thead>
                <tbody>
                    ${
            log.items.map(item => {
              return `<tr>
                            <td>${item.name}</td><td>${item.score}</td>
                        </tr>`
            }).join('')
        }
                 <tr>
                    <td colspan="2" class="text-right">
                        <div>
                            <span style="margin-right: 20px;">总分：${log.scoreCount}</span>
                            <span style="margin-right: 20px;">得分：${log.score}</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        `
      })
    },
    /**
     * 查看系统考核的任务
     * @param {Object} log 记录
     * */
    async lookSystemAssessmentTask(log) {
      let dialogObj = {};
      if (log.type === 1) {
        dialogObj.title = '相关巡检';
        const result = await this.queryInspectionTaskInfo(log.referId);
        dialogObj.messageHtml = `
          <div class="comment-panel">
             <div class="item">
                <div class="item-label">巡检计划</div>
                <div>${result.planName}</div>
             </div>
             <div class="item">
                <div class="item-label">巡检人</div>
                <div>${result.performerName}</div>
             </div>
             <div class="item">
                <div class="item-label">巡检时间</div>
                <div>${result.startTime}-${result.endTime}</div>
             </div>
             <div class="item">
                <div class="item-label">巡检点</div>
                <div>${result.pointCount}个</div>
             </div>
          </div>
        `
      } else {
        dialogObj.title = '相关工单';
        const result = await this.queryTicketInfo(log.referId),
            wordTypeMapping = {
              1: " 公共区域清洁",
              2: " 公共区域报修",
              3: " 物品进出",
              4: " 其它",
              5: " 投诉",
            };
        dialogObj.messageHtml = `
          <div class="comment-panel">
             <div class="item">
                <div class="item-label">类型</div>
                <div>${wordTypeMapping[result.type]}</div>
             </div>
             <div class="item">
                <div class="item-label">姓名</div>
                <div>${result.informantName}</div>
             </div>
             <div class="item">
                <div class="item-label">手机</div>
                <div>${result.informantPhone}</div>
             </div>
             <div class="item">
                <div class="item-label">内容</div>
                <div>${result.content}</div>
             </div>
          </div>
        `
      }
      this.$CSDialog.alert({
        ...dialogObj
      })
    },
    // 查询工单详情
    queryTicketInfo(ticketId) {
      return this.$fly.post(queryTicketUrl, {
        ticketId,
        regionId: this.$vc.getCurrentRegion().code,
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        return res.data;
      })
    },
    // 查询巡检任务详情
    queryInspectionTaskInfo(taskId) {
      return this.$fly.post(queryInspectionUrl, {
        taskId
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        return res.data;
      })
    },
    // 修改选中的tab
    changeTabBar(index) {
      this.checkedTab = +index;
      this.queryAssessmentItemsLog();
    },
    queryAssessmentRequest(type) {
      let dutyTypes = '';
      if (this.inspectionDepartmentList.length > 0) {
          dutyTypes = [];
          this.inspectionDepartmentList.forEach(item => {
              dutyTypes.push(item.id);
          })
          dutyTypes = JSON.stringify(dutyTypes);
      }
      return this.$fly.post(adminQueryItemLogUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        dutyTypes,
        type,
        startDate: this.addReportParams.startDate,
        endDate: this.addReportParams.endDate,
      })
      .then(res => {
        if (res.code !== 0) {
          return [];
        }
        return res.data;
      })
    },
    // 查询品质管理考核项记录
    async queryAssessmentItemsLog() {
      if (this.inspectionDepartmentList.length === 0) {
        const tab1 = this.queryAssessmentRequest(1),
            tab2 = this.queryAssessmentRequest(2),
            tab3 = this.queryAssessmentRequest(3);
        Promise.all([tab1, tab2, tab3])
            .then(res => {
              this.assessmentItemLogData = res[this.checkedTab - 1];
              const dutyTypes = new Set();
              res.forEach(tabVal => {
                tabVal.dutyTypes.forEach(type => {
                  dutyTypes.add(type);
                })
              })
              dutyTypes.forEach(type => {
                this.inspectionDepartmentList.push({
                  id: type,
                  name: this.departmentTypes[type],
                })
              })
            })
            .catch(() => {
              this.assessmentItemLogData = [];
            })
      } else {
        this.assessmentItemLogData = await this.queryAssessmentRequest(this.checkedTab);
      }
    },
    // 创建生成报告
    createReport() {
      if (this.addReportParams.name === '') {
        this.$vc.message('请填写报告名称');
        return ;
      }

      const details = this.inspectionDepartmentList.map(department => {
        return {
          dutyType: department.id,
        }
      });
      this.$fly.post(createQualityReportUrl, {
          ...this.addReportParams,
          regionCode: this.$vc.getCurrentRegion().code,
          isSubmit: 0,
          details,
      }, {
        headers: {
          isForm: true,
        }
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        this.$vc.toast('创建成功');
        this.$router.replace({name: 'QualityReportDetail', params: {id: res.data, type: '1'}});
      })
    },
    // 打开添加受检部门
    async openAddInspectionDepartmentDialog() {
      this.addInspectionDepartmentDialog.visible = true;
      const departmentList = await this.queryCanInspectionDepartments();
      const {departmentTypes} = this;
      this.addInspectionDepartmentDialog.canAddDepartmentList = [];
      departmentList.forEach(val => {
        this.addInspectionDepartmentDialog.canAddDepartmentList.push({
          id: val,
          name: departmentTypes[val]
        })
      });
      this.addInspectionDepartmentDialog.onConfirm = () => {
        const inspectionDepartmentIds = this.inspectionDepartmentList.map(item => item.id);
        if (!inspectionDepartmentIds.includes(this.addInspectionDepartmentDialog.currentCheckedDepartment.id)) {
          this.inspectionDepartmentList.push(this.addInspectionDepartmentDialog.currentCheckedDepartment);
        } else {
          this.$vc.toast('受检部门已存在');
        }
        this.addInspectionDepartmentDialog.onClose();
      }
    },
    /**
     * 移除受检部门
     * @param {Number} index 在受检部门中的下标
     * */
    deleteInspectionDepartment(index) {
      this.inspectionDepartmentList.splice(index, 1);
      this.queryAssessmentItemsLog();
    },
    // 查询可以受检的部门
    queryCanInspectionDepartments() {
      return this.$fly.post(queryEvaluatorUserDutyTypeUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
      })
      .then(res => {
        if (res.code !== 0) {
          return [];
        }
        return res.data;
      })
    },
  }
}
</script>

<style lang="stylus" scoped>
.page-view
  background #fff
  padding 20px 30px 30px 30px
  &-item
    color #000
    position relative
    font-size 24px
    max-length 370px
    overflow-y auto
    &:not(:last-of-type)
      margin-bottom 23px
    &.need-border
      &::after
        position absolute
        content " "
        bottom 0
        left 0
        width 100%
        height 1px
        background #F0F0F0
    &-name
      font-size 24px
      width 96px
      text-align right
      margin-right 40px
      display inline-block
      vertical-align middle
      &.top
        vertical-align top
    &-content
      display inline-block
      vertical-align middle
      .cs-input
        text-indent 10px
        border-radius 4px
        height 40px
        border 1px solid #979797
        &::placeholder
          color #999
      .cs-select
        margin-right 30px
        border-color #979797
        & + span
          margin 0 10px
        &:not(:last-of-type)
          margin-right 0
        .el-input
          font-size 24px
          height 40px
          display inline-flex
          flex-flow row nowrap
          align-items center
          input, .el-input__prefix
            height 40px
            line-height 40px
          /deep/.el-input__prefix, /deep/.el-input__suffix
            top 50%
            transform translateY(-50%)
            height 40px
            line-height 40px
          input::placeholder
              color #999
      /deep/.tab-bar
        div
          width 200px
          height 50px
          line-height 50px
          font-size 24px
      .table
        width 969px
        font-size 24px
  .btn
    height 40px
    padding 0 8px
    font-size 24px
  .btn-create-report
    margin 50px auto
    margin-bottom 0
    display block
    width 220px
    height 40px
    border-radius 4px
    padding 0

.dialog-form
  padding 27px 30px
  font-size 24px
  color #000
  &-item
    &:not(:last-of-type)
      margin-bottom 23px
    &-label
      width 96px
      text-align right
      margin-right 40px
      display inline-block
      vertical-align middle
    & > div
      display inline-block
      vertical-align middle
      .inspector
        margin-bottom 0
        display inline-block
        vertical-align middle
        &:not(:last-of-type)
          margin-bottom 17px
        .icon
          margin-left 10px
      .cs-select
        width 250px
      input
        width 250px
        height 40px
        border-radius 4px
        border 1px solid #979797
</style>
