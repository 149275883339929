<template>
    <div class="back-bar">
        <template v-if="step <= 0">
            <div @click="goBack">
                <i class="el-icon-arrow-left"></i>
                <div>返回</div>
            </div>
        </template>
        <template v-else>
            <div @click="prevStep">
                <i class="el-icon-arrow-left"></i>
                <div>返回上一步</div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "BackNavigatorBar",
    props: {
        step: {
            type: Number,
            default: 0
        },
        isCustomBack: {
            default: false,
            type: Boolean,
        },
    },
    methods: {
        goBack() {
            if (this.isCustomBack) {
              this.$emit('back');
            } else {
              this.$router.back();
            }
        },
        prevStep() {
            this.$emit('prevStep');
        },
    }
}
</script>

<style lang="stylus" scoped>
.back-bar
    width 100%
    // height 60px
    background #FFFFFF
    box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
    font-size 24px
    color var(--pc-theme-color)
    padding 18px 30px
    box-sizing border-box
    margin-bottom 20px
    div
        line-height 1
        i
            display inline-block
            vertical-align middle
        div
            line-height 24px
            display inline-block
            vertical-align middle
            cursor pointer
</style>